import React from "react"
import Helmet from 'react-helmet';
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import { GatsbyImage } from "gatsby-plugin-image"
import { Carousel, CarouselItem } from "react-bootstrap"
import 'bootstrap/dist/css/bootstrap.min.css';

import SEO from "../components/seo"

import PostLink from "../components/post-link"
import PostCard from "../components/post-link-cards"
import PostImage from "../components/post-image"
import Content from "../components/post-content"

const IndexPage = ({data: {site, slideShow, allMarkdownRemark: { edges }}}) => {


  const categories = edges
    .filter(edge => edge.node.frontmatter.catagory==="Produkte") // You can filter your posts based on some criteria
    .map(edge => <div class="w-full rounded hover:opacity-90"> <PostLink key={Math.random().toString(36).substr(2, 9)} post={edge.node}  /></div> )

    const pictures = edges
    .filter(edge => edge.node.frontmatter.catagory==="Impressionen" && edge.node.frontmatter.page==="Start") // You can filter your posts based on some criteria
    .map(edge => <div class="w-full rounded hover:opacity-90"> <PostImage key={Math.random().toString(36).substr(2, 9)} post={edge.node}  /></div> )

    const standorte = edges
    .filter(edge => edge.node.frontmatter.catagory==="Standorte") // You can filter your posts based on some criteria
    .map(edge => <div class="rounded hover:opacity-90"> <PostCard key={Math.random().toString(36).substr(2, 9)} post={edge.node}  /></div> )
     
    const schatzkammer = edges
    .filter(edge => edge.node.frontmatter.catagory==="Content" && edge.node.frontmatter.page==="Schatzkammer") // You can filter your posts based on some criteria
    .map(edge => <div class="rounded hover:opacity-90"> <Content key={Math.random().toString(36).substr(2, 9)} post={edge.node} /></div> )

    const café = edges
    .filter(edge => edge.node.frontmatter.catagory==="Content" && edge.node.frontmatter.page==="Café") // You can filter your posts based on some criteria
    .map(edge => <div class="rounded hover:opacity-90"> <Content key={Math.random().toString(36).substr(2, 9)} post={edge.node} /></div> )

    const mehr = edges
    .filter(edge => edge.node.frontmatter.catagory==="Content" && edge.node.frontmatter.page==="Mehr") // You can filter your posts based on some criteria
    .map(edge => <div class="rounded hover:opacity-90"> <Content key={Math.random().toString(36).substr(2, 9)} post={edge.node} /></div> )

    const dienstleistung = edges
    .filter(edge => edge.node.frontmatter.catagory==="Content" && edge.node.frontmatter.page==="Dienstleistungen") // You can filter your posts based on some criteria
    .map(edge => <div class="rounded hover:opacity-90"> <Content key={Math.random().toString(36).substr(2, 9)} post={edge.node} /></div> )

    const schlussWort = edges
    .filter(edge => edge.node.frontmatter.catagory==="Content" && edge.node.frontmatter.page==="Schluss") // You can filter your posts based on some criteria
    .map(edge => <div class="rounded hover:opacity-90"> <Content key={Math.random().toString(36).substr(2, 9)} post={edge.node} /></div> )

    

   
  return (
    <Layout>
      <Helmet>
        <title>Schatzkammer Lübeck | Antiquitäten</title>
        <meta name="description" content="Herzlich willkommen bei der Schatzkammer Lübeck - Ihre 1. Adresse für Antiquitäten, Möbel und Kurioses, Haushaltsauflösungen und Nachlassverwertungen in Lübeck. Wir sind Experte für Haushaltsauflösungen, Entrümpelungen und Nachlassverwertungen in Lübeck und Umgebung. Wenn Sie auf der Suche nach einer professionellen, zuverlässigen und schnellen Abwicklung sind, dann sind Sie bei mir genau richtig. " />
        <meta name="keywords" content="Schatzkammer, Lübeck, Ankauf, Verkauf, Entrümpelung, Haushaltsauflösung, Nachlassverwertung"  />
      </Helmet>
      
        
      <Carousel> {slideShow.edges.map(({node}) => (
      <CarouselItem key={node.id}>
        <GatsbyImage class="w-full" image={node.childImageSharp.gatsbyImageData} alt={node.base} />
      </CarouselItem>
    ))}
</Carousel>


<section class="border-b py-8">
    <div class="w-full mb-4">
      <div class="md:flex md:flex-row md:pb-4 ">
        <div class="md:w-1/2 pr-6">
          <h2 class="font-thin text-red-700">Was ist die Schatzkammer?</h2>
          {schatzkammer}
        </div>
        <div class="md:w-1/2 pr-6">
          <h2 class="font-thin text-red-700">Unser Café</h2>
          {café}
        </div>
      </div>
      <div class="md:flex md:flex-row pb-4">
        <div class="md:w-1/2 pr-6">
          <h2 class="font-thin text-red-700">Mehr über uns</h2>
          {mehr}
        </div>
        <div class="md:w-1/2 pr-6">
          <h2 class="font-thin text-red-700">Dienstleistungen</h2>
          {dienstleistung}
        </div>
      </div>
      {schlussWort}
      </div>
</section>



      <section class="border-b py-8 "> 
      <h1 class="font-serif w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800" >Standorte</h1>
            <div class="w-full mb-4">
              <div class="h-1 mx-auto skBrown w-64 opacity-25 my-0 py-0 rounded-t"></div>
            </div>   
            <div class="grid md:grid-cols-3 grid-cols-1 gap-2 mx-auto p-1 m-8">
      
    
            {standorte}

            </div>
      
 
</section>




      <section class="border-b py-8">
            <h1 class="font-serif w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800" >An- und Verkauf</h1>
            <div class="w-full mb-4">
              <div class="h-1 mx-auto skBrown w-64 my-0 opacity-25 py-0 rounded-t"></div>
            </div>   
            <div class="grid md:grid-cols-4 grid-cols-1 gap-2 mx-auto p-1 m-8">
               {categories}
        </div>
      </section>

      <section class="py-4"> 
        
      <div class="grid md:grid-cols-4 grid-cols-1 mx-auto gap-2 m-8"> 
      {pictures}
      </div>
      </section>
      


    </Layout>
  )


}

export default IndexPage
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { order: ASC, fields: [frontmatter___title] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            thumbnail
            catagory
            page
            metaDescription
          }
          html
        }
      }
    }
    slideShow: allFile(
      sort: {fields: base, order: ASC}
      filter: {base: {regex: "/DSC01/"}}
    ) {
      edges {
        node {
          id
          relativePath
          base
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              quality: 90
              transformOptions: {
                fit: FILL
              }   
            )
          }
        }
      }
    }
  }
`